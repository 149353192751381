import {
  getCustomerList,
  addUser,
  deleteUser,
  patchUserByAdmin,
  addGroup,
  patchGroupByAdmin,
  deleteGroup,
  addAndVerifySensor,
  deleteSensorFromCustomerGroup,
  putSensor,
  addOutput,
  patchOutput,
  deleteOutput,
  putOutput,
  forceUserOutputDump,
} from "../../services/api";

const state = {
  userList: [],
  selectedUserID: null,
  selectedGroupID: null,
  selectedSensorID: null,
  selectedOutputID: null,
};
const getters = {
  getUserList: (state) => state.userList.filter((us) => us.role !== "admin"),
  getSelectedUserObj: (state) => state.userList.find((us) => us._id === state.selectedUserID),
  getGroups: (state, getters) => (getters.getSelectedUserObj ? getters.getSelectedUserObj.groups : []),
  getOutputs: (state, getters) => (getters.getSelectedUserObj ? getters.getSelectedUserObj.outputs : []),
  getSelectedUserID: (state) => state.selectedUserID,
  getSelectedGroupID: (state) => state.selectedGroupID,
  getSelectedOutputID: (state) => state.selectedOutputID,
  getSelectedGroupObj: (state, getters) => (getters.getSelectedUserObj ? getters.getSelectedUserObj.groups.find((gr) => gr._id === state.selectedGroupID) : null),
  getSensors: (state, getters) => (getters.getSelectedGroupObj ? getters.getSelectedGroupObj.sensors : []),
  getAllSensors: (state, getters) => (getters.getSelectedUserObj ? getters.getSelectedUserObj.groups.map((gr) => gr.sensors).flat(Infinity) : []),
  getSelectedSensorID: (state) => state.selectedSensorID,
  getSelectedSensorObj: (state, getters) => (state.selectedSensorID ? getters.getSensors.find((sn) => sn._id === state.selectedSensorID) : null),
  getSelectedOutputObj: (state, getters) => (state.selectedOutputID ? getters.getOutputs.find((sn) => sn._id === state.selectedOutputID) : null),
};
const actions = {
  async refresh({ commit, getters }) {
    const users = await getCustomerList();
    if (users.length === 0) {
      commit("SET_SELECTED_USER_ID", null);
      commit("SET_SELECTED_GROUP_ID", null);
      commit("SET_SELECTED_SENSOR_ID", null);
    }

    // Ri-seleziono utente
    if (state.selectedUserID && users.some((user) => user._id === state.selectedUserID)) {
      // Nothing
    } else {
      commit("SET_SELECTED_USER_ID", users[0]._id);
    }

    /*
    // Ri-seleziono gruppo
    if (state.selectedGroupID && getters.getGroups.some((group) => group._id === state.selectedGroupID)) {
      // Nothing
    } else {

    }

    // Ri-seleziono sensore
    */
  },
  async fetchUserList({ commit }) {
    const users = await getCustomerList();
    users.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()); // Ordino dal più recente al più vecchio
    commit("SET_USER_LIST", users);
  },
  selectUserByID({ commit }, userID) {
    commit("SET_SELECTED_USER_ID", userID);
  },
  selectGroupByID({ commit }, groupID) {
    commit("SET_SELECTED_GROUP_ID", groupID);
  },
  selectSensorByID({ commit }, sensorID) {
    commit("SET_SELECTED_SENSOR_ID", sensorID);
  },
  selectOutputByID({ commit }, outputID) {
    commit("SET_SELECTED_OUTPUT_ID", outputID);
  },
  /* USERS */
  async addNewUser({ commit, dispatch }, userObj) {
    await addUser(userObj);
    dispatch("fetchUserList");
  },
  async removeUser({ commit, dispatch }, userID) {
    await deleteUser(userID);
    dispatch("fetchUserList");
  },
  async patchExistingUser({ commit, dispatch }, patchParams) {
    await patchUserByAdmin(patchParams._id, patchParams.obj);
    dispatch("fetchUserList");
  },
  async forceOutputDump({ commit, dispatch }) {
    if (state.selectedUserID) {
      await forceUserOutputDump(state.selectedUserID);
    }
  },
  /* GROUPS */
  async addNewGroup({ commit, dispatch }, groupName) {
    if (state.selectedUserID) {
      await addGroup(state.selectedUserID, groupName);
      dispatch("fetchUserList");
    }
  },
  async patchExistingGroup({ commit, dispatch }, patchParams) {
    if (state.selectedUserID) {
      delete patchParams.obj._id;
      await patchGroupByAdmin(state.selectedUserID, patchParams._id, patchParams.obj);
      dispatch("fetchUserList");
    }
  },
  async removeGroup({ commit, dispatch }, groupID) {
    if (state.selectedUserID) {
      await deleteGroup(state.selectedUserID, groupID);
      dispatch("fetchUserList");
    }
  },
  /* SENSORS */
  async addAndVerifySensorToCustomer({ commit, dispatch }, sensorObj) {
    if (state.selectedUserID && state.selectedGroupID && sensorObj) {
      await addAndVerifySensor(state.selectedUserID, state.selectedGroupID, sensorObj);
      dispatch("fetchUserList");
    }
  },
  async removeSensorFromCustomer({ commit, dispatch }, sensorID) {
    if (state.selectedUserID && state.selectedGroupID && sensorID) {
      await deleteSensorFromCustomerGroup(state.selectedUserID, state.selectedGroupID, sensorID);
      dispatch("fetchUserList");
    }
  },
  async putSensorToCustomer({ commit, dispatch }, sensorObj) {
    if (state.selectedUserID && state.selectedGroupID && sensorObj) {
      const sensorID = sensorObj._id;
      delete sensorObj._id;
      await putSensor(sensorID, sensorObj);
      dispatch("fetchUserList");
    }
  },
  /* OUTPUTS */
  async addNewOutput({ commit, dispatch }, outputObj) {
    if (state.selectedUserID) {
      await addOutput(state.selectedUserID, outputObj);
      dispatch("fetchUserList");
    }
  },
  async patchExistingOutput({ commit, dispatch }, patchParams) {
    if (state.selectedUserID) {
      delete patchParams.obj._id;
      await patchOutput(state.selectedUserID, patchParams._id, patchParams.obj);
      dispatch("fetchUserList");
    }
  },
  async removeOutput({ commit, dispatch }, outputID) {
    if (state.selectedUserID) {
      await deleteOutput(state.selectedUserID, outputID);
      dispatch("fetchUserList");
    }
  },
  async putExistingOutput({ commit, dispatch }, putObj) {
    if (state.selectedUserID) {
      await putOutput(state.selectedUserID, putObj._id, putObj);
      dispatch("fetchUserList");
    }
  },
};
const mutations = {
  SET_USER_LIST: (state, userList) => (state.userList = userList),
  SET_SELECTED_USER_ID: (state, userID) => (state.selectedUserID = userID),
  SET_SELECTED_GROUP_ID: (state, groupID) => (state.selectedGroupID = groupID),
  SET_SELECTED_SENSOR_ID: (state, sensorID) => (state.selectedSensorID = sensorID),
  SET_SELECTED_OUTPUT_ID: (state, outputID) => (state.selectedOutputID = outputID),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
