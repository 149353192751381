import axios from "axios";
import configModule from "../config";

function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

/******************* AUTH *******************/

/* Login */
export async function login(username, password) {
  const response = await axios.post(configModule.API_ENDPOINT + "auth/login", {
    username: username,
    password: password,
  });
  return response.data;
}

/* Logout */
export async function logout(refreshToken) {
  const response = await axios.delete(configModule.API_ENDPOINT + "refresh_token", {
    data: { refresh_token: refreshToken },
  });
  return response.data;
}

/* Refresh Token */
export async function refreshToken(userID, refresh_token) {
  const response = await axios.post(configModule.API_ENDPOINT + "refresh_token", {
    id: userID,
    refreshToken: refresh_token,
  });
  return response.data;
}

/******************* USER *******************/

export async function getUserInfo(userID) {
  if (!userID) {
    const response = await axios.get(configModule.API_ENDPOINT + "users");
    return response.data;
  } else {
    const response = await axios.get(configModule.API_ENDPOINT + "users/" + userID);
    return response.data;
  }
}

// Prende logo utente da FTP
export async function getUserLogo() {
  try {
    const response = await axios.get(configModule.API_ENDPOINT + "ftp/logo", { responseType: "arraybuffer" });
    if (response.status !== 200) return null;
    return _arrayBufferToBase64(response.data);
  } catch (e) {
    console.log(e);
  }
}

export async function patchUser(values) {
  const response = await axios.patch(configModule.API_ENDPOINT + "users", values);
  return response.data;
}

/******************* DEVICE DATA *******************/

export async function getLastEvents(device, firstDate, lastDate, hourFormat, lastOne) {
  const response = await axios.get(configModule.API_ENDPOINT + "sensor_data", {
    params: {
      device: device,
      firstDate: firstDate,
      lastDate: lastDate,
      hourFormat: hourFormat,
      compactFormat: true,
      last: lastOne || false,
    },
  });
  return response.data;
}

export async function getLastStats(device, firstDate, lastDate) {
  const response = await axios.get(configModule.API_ENDPOINT + "sensor_data/stats", {
    params: {
      device: device,
      firstDate: firstDate,
      lastDate: lastDate,
    },
  });
  return response.data;
}

export async function getLastCounters(device, firstDate, lastDate, valueType) {
  const response = await axios.get(configModule.API_ENDPOINT + "sensor_data/counters", {
    params: {
      device: device,
      firstDate: firstDate,
      lastDate: lastDate,
      valueType: valueType,
    },
  });
  return response.data;
}

export async function getFtpImage(cameraID) {
  const response = await axios.get(configModule.API_ENDPOINT + "ftp/camera/" + cameraID, { responseType: "arraybuffer" });
  if (response.status === 204) return null;
  return _arrayBufferToBase64(response.data);
}

/******************* DEVICE CONFIG *******************/
export async function patchSensor(sensorID, values) {
  const response = await axios.patch(configModule.API_ENDPOINT + "sensors/" + sensorID, values);
  return response.data;
}

/******************* LOG *******************/
export async function getLogs(device, type, userID) {
  const params = {};
  if (device) {
    params["device"] = device;
  }
  if (type) {
    params["type"] = type;
  }
  if (userID) {
    params["userID"] = userID;
  }
  const response = await axios.get(configModule.API_ENDPOINT + "logs/", {
    params: params,
  });
  return response.data;
}

/******************* ADMIN *******************/
export async function getCustomerList() {
  const response = await axios.get(configModule.API_ENDPOINT + "users");
  return response.data;
}

export async function addUser(userObj) {
  const response = await axios.post(configModule.API_ENDPOINT + "users", userObj);
  return response.data;
}

export async function deleteUser(userID) {
  const response = await axios.delete(configModule.API_ENDPOINT + "users/" + userID);
  return response.data;
}

export async function patchUserByAdmin(userID, patchObj) {
  const response = await axios.patch(configModule.API_ENDPOINT + "users/" + userID, patchObj);
  return response.data;
}

export async function addGroup(userID, groupName) {
  const response = await axios.post(configModule.API_ENDPOINT + "users/" + userID + "/groups", {
    groupName: groupName,
  });
  return response.data;
}

export async function deleteGroup(userID, groupID) {
  const response = await axios.delete(configModule.API_ENDPOINT + "users/" + userID + "/groups/" + groupID);
  return response.data;
}

export async function patchGroupByAdmin(userID, groupID, patchObj) {
  const response = await axios.patch(configModule.API_ENDPOINT + "users/" + userID + "/groups/" + groupID, patchObj);
  return response.data;
}

export async function addAndVerifySensor(userID, groupID, sensorObj) {
  const sensorResponse = await axios.post(configModule.API_ENDPOINT + "sensors", sensorObj);
  const response = await axios.post(configModule.API_ENDPOINT + "users/" + userID + "/groups/" + groupID + "/sensors", {
    sensorToken: sensorResponse.data.token,
  });
  return response.data;
}

export async function deleteSensorFromCollection(sensorID) {
  const response = await axios.delete(configModule.API_ENDPOINT + "sensors/" + sensorID, {
    params: {
      permanentDelete: true,
    },
  });
  return response.data;
}

export async function deleteSensorFromCustomerGroup(userID, groupID, sensorID) {
  const response = await axios.delete(configModule.API_ENDPOINT + "users/" + userID + "/groups/" + groupID + "/sensors/" + sensorID);
  await deleteSensorFromCollection(sensorID);
  return response.data;
}

export async function putSensor(sensorID, sensorObj) {
  const response = await axios.put(configModule.API_ENDPOINT + "sensors/" + sensorID, sensorObj);
  return response.data;
}

export async function patchOutput(userID, outputID, patchObj) {
  const response = await axios.patch(configModule.API_ENDPOINT + "users/" + userID + "/outputs/" + outputID, patchObj);
  return response.data;
}

export async function putOutput(userID, outputID, putObj) {
  const response = await axios.put(configModule.API_ENDPOINT + "users/" + userID + "/outputs/" + outputID, putObj);
  return response.data;
}

export async function addOutput(userID, outputObj) {
  const response = await axios.post(configModule.API_ENDPOINT + "users/" + userID + "/outputs", outputObj);
  return response.data;
}

export async function deleteOutput(userID, outputID) {
  const response = await axios.delete(configModule.API_ENDPOINT + "users/" + userID + "/outputs/" + outputID);
  return response.data;
}

export async function forceUserOutputDump(userID) {
  const response = await axios.post(configModule.API_ENDPOINT + "users/" + userID + "/outputs/dump");
  return response.data;
}
